/* App.css */
body {
  margin: 0;
  font-family: Georgia;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ffcc00, #ff6699, #66ccff, #ff99cc);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  /* background-color: rgb(184, 184, 178); */
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.App {
  display: flex;
  align-items: center;
  background-color: rgb(228, 228, 218);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  animation: slideInTop 2s ease-out forwards;
  opacity: 0;
}

@keyframes slideInTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.my-image {
  height: 300px;
  width: auto;
  border-radius: 10px;
  animation: slideInSide 2.5s ease-out forwards;
  opacity: 0;
}

@keyframes slideInSide {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.content {
  margin-top: 0;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: slideInTop 2s ease-out forwards;
  opacity: 0;
}

h1 {
  margin-top: 0;
  font-size: 38px;
  color: #333;
}

p {
  margin-top: 0;
  font-size: 18px;
  color: #666;
  max-width: 400px;
  font-family: Times New Roman;
}

.icon-links {
  display: flex;
  gap: 10px;
  margin-top: 30px;
}

.icon-links img {
  width: 40px;
  height: auto;
  transition: transform 0.3s;
}

.icon-links img:hover {
  transform: scale(1.3);
}